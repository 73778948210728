/* portfolio section */
.portfolioTop{
    padding: 80px 0;
    background-color: whitesmoke;
}


.portfolioText{
    text-align: center;
    width: 70%;
    margin: auto;
    font-family: 'poppins';
    padding: 20px;
}

.portfolioText>h2{
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 600;
    padding-bottom: 10px;
}

.portfolioText>p{
    color: red;
    font-size: 14px;
}

.portfolioImages{
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 30px;
}

.portfolioImages>img{
    width: 300px;
    height: 200px;
    border-radius: 10px;
}


.portfolioProjects{
    border: 0.01px solid ;
    height: 100px;
    text-align: center;
    color: white;
    background-color: #2f333d ;
    font-family: 'poppins';
    text-transform: uppercase;
    margin: auto;
}

.h4{
    color: white;
    text-decoration: none;
    border: 1px solid white;
    width: 50%;
    padding: 10px;
    margin: 20px auto auto ;
}

.portfolioProjects>h4:hover{
    transform: scale(1.01); 
    transition-duration: 10s ease-out;
    cursor: pointer;

}
.portfolioImages>img:hover{
    transform: scale(1.1); 
    transition-duration: 1000ms;
    border-radius: 20px;
    cursor: pointer;

}


/* media screen  tab view */

@media screen and (max-width : 929px) {
    .portfolioTop{
        padding-top: 10px;
        background-color: whitesmoke;
    }
    
    
    .portfolioText{
        text-align: center;
        width: auto;
        margin: auto;
        font-family: 'poppins';
        padding: 20px;
    }
    
    .portfolioText>h2{
        text-transform: uppercase;
        font-size: 24px;
    }
    
    .portfolioText>p{
        color: red;
        font-size: 16px;
        font-weight: 500;
    }
    
    .portfolioImages{
        display: flex;
        gap: 20px;
        padding: 40px;
        flex-direction: column;
    }
    
    .portfolioImages>img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    
    .portfolioProjects{
        margin-top: 20px;
        height: 80px;
        text-align: center;
        color: white;
        background-color: #2f333d ;
        font-family: 'poppins';
        text-transform: uppercase;
    }
    
    .portfolioProjects>h4{
        border: 1px solid white;
        width: 50%;
        padding: 10px;
        margin: 20px auto auto ;
    }
    .portfolioProjects>h4:hover{
        transform: scale(1.1); 
        transition-duration: 1000ms;
        cursor: pointer;
    
    }
    .portfolioImages>img:hover{
        transform: scale(1.01); 
        transition-duration: 1000ms;
        border-radius: 20px;
        cursor: pointer;
    
    }
}

/* media screen  mobile view */


@media screen and (max-width : 460px) {
    .portfolioTop{
        padding-top: 10px;
        background-color: whitesmoke;
    }
    
    .portfolioText{
        text-align: center;
        width: auto;
        margin: auto;
        font-family: 'poppins';
        padding: 20px;
    }
    
    .portfolioText>h2{
        text-transform: uppercase;
        font-size: 17px;
    }
    
    .portfolioText>p{
        color: red;
        font-size: 14px;
        font-weight: 500;
    }
    
    .portfolioImages{
        display: flex;
        gap: 20px;
        padding: 20px;
        flex-direction: column;
    }
    
    .portfolioImages>img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    
    .portfolioProjects{
        margin-top: 10px;
        height: 80px;
        text-align: center;
        color: white;
        background-color: #2f333d ;
        font-family: 'poppins';
        text-transform: uppercase;
    }
    
    .portfolioProjects>h4{
        border: 1px solid white;
        width: 50%;
        font-size: 14px;
        letter-spacing: 2px;
        padding: 10px;
        margin: 20px auto auto ;
    }

    .portfolioProjects>h4:hover{
        transform: scale(1.1); 
        transition-duration: 1000ms;
        cursor: pointer;
    
    }
    .portfolioImages>img:hover{
        transform: scale(1.01); 
        transition-duration: 1000ms;
        border-radius: 20px;
        cursor: pointer;
    
    }
}
