/* our services */
.ourServices{
    width: 100%;
    padding-bottom: 20px;
    background-color: white;
    font-family: 'poppins';
    padding-top: 80px;
}

.topSecServices{
    padding: 10px;
    margin: auto;
    width: 80%;
    text-align: center;
}

.prod_flex img{
    object-fit:contain;
}

.topSecServices>p{
    color: red;
    font-size: 14px;
}
.topSecServices>h2{
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
}
.servicesFlex{
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    gap: 20px;
    grid-auto-rows: minmax(10px , auto);
    margin:5px;
    padding: 10px;
}
.insideServices{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 400px;
    height: 400px;
    font-family: 'poppins';
    border-radius: 15px;
    padding: 20px 10px;
}

.insideServices:hover{
    transform: scale(1.01); 
    transition-duration: 1000ms;
    border-radius: 10px;
    cursor: pointer;
}
.insideServices>h4{
    padding-top: 20px;
    text-align: center;
    padding-bottom: 10px;
    font-size: 16px;
    padding-left: 5px;
}

.insideServices>p{
    font-size: 12px;
    text-align: center;
    width: 90%;
    margin: auto;
}
.insideServices>img{
    display: flex;
    align-items: center;
    margin: auto;
    object-fit:inherit;    
    width: 350px;
    height: 200px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.prod_flex{
    display: grid;
    grid-template-columns: auto auto ;
    gap: 10px;
    text-align: center;
}
.prod_flex img{
    width: 200px;
    height: 90px;
}




/* media screen  tab view */

@media screen and (max-width : 929px) {
    .ourServices{
        height: auto;
        padding-top: 0px;
        background-color: white;
        font-family: 'poppins';
    }
    
    .topSecServices{
        padding: 5px;
        margin: auto;
        width: auto;
        text-align: center;
    }
    
    .topSecServices>p{
        color: red;
        font-size: 16px;
    }
    .topSecServices>h2{
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
    }
    .servicesFlex{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .insideServices{
        width: auto;
        height: auto;
        font-family: 'poppins';
        border-radius: 10px;

    }
    .insideServices>h4{
        font-size: 24px;
        text-align: center;
    }
    
    .insideServices>p{
        text-align: center;
        padding-left: 5px;
        font-size: 12px;
        width: 90%;
        margin: auto;
    }
    .insideServices>img{
        object-fit:cover;    
        width: 90%;
        height: 100%;
        margin-left: 50px;

    }
    .insideServices:hover{
        transform: scale(1.05); 
        transition-duration: 1000ms;
        border-radius: 10px;
        cursor: pointer;
        
    }
}

/* media screen  mobile view */


@media screen and (max-width : 460px) {
    .ourServices{
        height: auto;
        padding-top: 0px;
        background-color: white;
        font-family: 'poppins';
    }
    
    .topSecServices{
        padding: 5px;
        margin: auto;
        width: auto;
        text-align: center;
    }
    
    .topSecServices>p{
        color: red;
        font-size: 14px;
        font-weight: 500;
    }
    .topSecServices>h2{
        font-size: 17px;
        text-transform: uppercase;
    }
    .servicesFlex{
        display: flex;
        flex-direction: column;
    }
    .insideServices{
        width: auto;
        height: auto;
        font-family: 'poppins';
    }
    .insideServices>h4{
        font-size: 20px;
        text-align: center;
    }
    
    .insideServices>p{
        text-align: center;
        font-size: 12px;
        width: 100%;
        margin: auto;
    }
    .insideServices>img{
        width: 100%;
        margin-left: 1px;

    }
    .insideServices:hover{
        transform: scale(1.05); 
        transition-duration: 1000ms;
        cursor: pointer;
        
    }

    .prod_flex{
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        text-align: center;
    }
    .prod_flex img{
        width: 11rem;
        height: 7rem;
    }
}
