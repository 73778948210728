/* header section image and text */
body{
    overflow-x: hidden;
    
}

.headImg{
    width: auto;
    height: 110vh;
    background-size: cover;
    position: relative;
}

.bgimg{
    position:absolute;
    left:0px;
    top:0px;
    z-index:-1;
}

.headText{
    text-align: center;
    width: 70%;
    margin: auto;
    padding-top: 350px;
}

.headText>h1{
    font-family: 'poppins';
    font-size: 32px;
    font-weight: 600;
    color: white;
    padding-bottom: 20px;
}

.headText>p{
    width: 60%;
    margin: auto;
    color: white;
    font-family: 'saira';
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 25px;
}
.contBtn{
    padding: 6px 30px;
    border-radius: 10px;
    border: transparent;
    font-family: 'poppins';
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: rgba(255, 255, 255, 0.925);
    color: black;
}

.contBtn>a{
    text-decoration: none;
}

.contBtn:hover{
    transform: scale(1.1); 
    transition-duration: 1000ms;
    cursor: pointer;
    background-color: white;
    color: blue;
}




.wtsapp{
    top: 85%;
    left: 91%;
    width: 70px;
    position: fixed;
    z-index: 100;
    cursor: pointer;
    padding: 7px;
    background-color: rgb(14, 80, 14);
    border-radius: 50%;
}

.wtsapp:hover{
    transform: scale(1.1); 
    transition-duration: 1000ms;

}
.phonewtsapp{
    display: none;
}
.quotes{
    border-radius: 50px 1px;
    padding: 50px;
    width: 90%;
    align-items: center;
    margin: auto;
    background-color: #2f333d;
   
}

.hold{
    color: green;
    font-size: 28px;
}
.quo_p{
    font-size: 18px;
    width: 90%;
    align-items: center;
    text-align: center;
    margin: auto;
    color: white;
}


/* media screen  tab view */

@media screen and (max-width : 929px) {
    .headImg{
        width: auto;
        height: 80vh;
        background-size: cover;
        padding-top: 30vh;
    }
    
    .headText{
        width: auto;
        position: absolute;
        top: 80px;
    }
    .bgimg{
        position:absolute;
        left:0px;
        top:0px;
        object-fit:cover;
        z-index:-1;
    }
    
    .headText>h1{
        font-family: 'poppins';
        width: 90%;
        margin: auto;
        font-size: 28px;
        font-weight:500;
        color: white;
        padding-bottom: 20px;
    }
    
    .headText>p{
        width: 70%;
        margin: auto;
        color: white;
        font-family: 'poppins';
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 25px;
    }
    .contBtn{
        padding: 6px 35px;
        border-radius: 10px;
        border: transparent;
        font-family: 'saira';
        font-size: 20px;
        font-weight: 800;
        letter-spacing: 2px;
        background-color: rgba(255, 255, 255, 0.548);
        color: white;
    }
    
    
    
    
    .wtsapp{
        top: 85%;
        left: 82%;
        width: 85px;
        position: fixed;
        cursor: pointer;
        padding: 7px;
        background-color: rgb(14, 80, 14);
        border-radius: 50%;
    }
    
    .wtsapp:hover{
        transform: scale(1.1); 
        transition-duration: 1000ms;
    
    }
    .phonewtsapp{
    display: none;
}

}

/* media screen  mobile view */


@media screen and (max-width : 460px) {

    .headImg{
        width: 100%;
        height: 20vh;
        padding-top: 290px;
    }
    
    .headText{
        width: auto;
        position: absolute;
        top: -60px;
    }
    .bgimg{
        position:absolute;
        left:0px;
        top:0px;
        object-fit:cover;
        z-index:-1;
    }
    
    .headText>h1{
        font-family: 'poppins';
        font-size:17px;
        font-weight: 600;
        color: white;
        padding-bottom: 10px;
    }
    
    .headText>p{
        width: 90%;
        margin: auto;
        color: white;
        font-family: 'poppins';
        font-size: 12px;
        font-weight: 400;
        padding-bottom: 10px;
    }
    .contBtn{
        padding: 5px 12px;
        border-radius: 10px;
        border: transparent;
        font-family: 'saira';
        font-size: 13px;
        font-weight: 800;
        letter-spacing: 2px;
        background-color: rgba(255, 255, 255, 0.548);
        color: white;
    }
    
    
    
    
    .wtsapp{
        top: 88%;
        left: 75%;
        width: 65px;
        height: 65px;
        position: fixed;
        z-index: 100;
        cursor: pointer;
        padding: 7px;
        background-color: rgb(14, 80, 14);
        border-radius: 50%;
        display: block;
    }
    
    .phonewtsapp{
        display: block;
    }
    .quotes{
        border-radius: 30px 1px;
        padding: 10px;
        width: 90%;
        align-items: center;
        margin: auto auto 30px auto ;
        background-color: #2f333d;
    }
    .hold{
        color: green;
        font-size: 22px;
    }
    .quo_p{
        line-height: 32px;
        font-size: 12px;
        width: 95%;
        align-items: center;
        text-align: center;
        margin: auto;
        color: white;
    }

}
