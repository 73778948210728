* {
	padding: 0;
	margin: 0;
    font-family: 'poppins';
    overflow-x: hidden;
}
.navBar {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 80px;
	padding: 0 2rem;
    background-color: #23262f;
	color: white;
    z-index: 10;
}
#logonav{
	display: none;
}
.navinside{
    margin-left: 600px;
}
h2>a{
    color: white;
    text-decoration: none;
}
nav a {
	margin: 0 1rem;
	color: white;
	text-decoration: none;
}

nav a:hover {
	color: red;
}
.whyus{
	color: yellow;
}

.whyus:hover{
	color: red;
}

.navBar .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: white;
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}
.navBar div,
nav {
	width: 100rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.logoC{
	width:300px ;
	margin-left: 100px;
	height: auto;
}


/* media screen  tab view */

@media screen and (max-width : 929px) {
	.navBar .nav-btn {
		visibility: visible;
		opacity: 1;
	}
    .navBar{
        position: fixed;
        z-index: 100;
        width: 100%;
    }
	.navBar div,nav {
		width: 20rem;
		display: flex;
		align-items: center;
	}

	.navBar nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: #23262f;
		transition: 1s;
        z-index: 10;
	}

	.navBar .responsive_nav {
		transform: translateY(100vh);
	}

    .navinside{
        margin-left: 0px;
    }

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
        z-index: 100 ;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
	#logonav{
		display: block;
	}
	.logo{
        display: flex;
		padding-top: 50px;
        gap: 0px;
    }
    .logo>img{
        width: 20px;
        height: 20px;
    }

    .logo>a>img{
        width: 35px;
        
    }
	.logoC{
		width:150px ;
		margin-left: 0px;
	}
}