/* footer */
.footer{
    padding-top: 40px;
    color: white;
    font-family: 'poppins';
    background-color: #23262f;
}

.footerHead{
    display: flex;
    justify-content: space-around;
}
.flex{
    display: flex;
    gap: 50px;
}

.contact>p{
    padding-bottom: 12px;
    padding-left: 20px;
}


.phone{
    cursor: pointer;
    letter-spacing: 1px;
    display: flex;
    gap:10px;
    padding-bottom: 5px;
}
.phone>h2{
    padding-top: 5px;
    font-size: 17px;
}
.phone>img{
    margin-top: 8px;
    width: 20px;
    height: 20px;
}
.location{
    display: flex;
    gap: 10px;
}
.location>h1{
    font-size: 17px;
    padding-top: 10px;
}
.address>p{
    font-size: 14px;
}
.location>img{
    margin-top: 8px;
    width: 20px;
    height: 20px;
}


.copyright{
    text-align: center;
    padding: 10px;
    font-size: 12px;
    letter-spacing: 1px;
    background-color: #13141a;
}
.phoneNumber{
    text-decoration: none;
    color: aliceblue;
}
.logoCF{
    width: 200px;
}

.social_media{
    width: 20px;
    border: 1px solid whitesmoke;
    padding: 10px;
    border-radius: 20px;
    margin: 10px;
}


/* media screen  tab view */

@media screen and (max-width : 929px) {
    .footer{
        padding-top: 20px;
        color: white;
        font-family: 'poppins';
        background-color: #23262f;
    }
    .brandName{
        font-size: 14px;
        text-align: center;
        padding-bottom: 10px;
    }
    .footerHead{
        display: flex;
        flex-direction: column;
    }

    
    .contact>p{
        font-size: 16px;
        padding-bottom: 18px;
        padding-left: 30px;
    }
    
    
    .phone{
        letter-spacing: 1px;
        display: flex;
        gap: 10px;
        padding-bottom: 5px;
    }
    .phone>h2{
        padding-top: 5px;
        font-size: 13px;
    }
    .phone>img{
        margin-top: 8px;
        width: 15px;
        height: 15px;
    }
    .location{
        display: flex;
        gap: 10px;
    }
    .location>h1{
        font-size: 17px;
        padding-top: 10px;
    }
    .address>p{
        padding-top: 10px;
        font-size: 14px;
    }
    .location>img{
        width: 30px;
        height: 30px;
    }
    .contact>p>a:hover{
        color: red;
    }
    .flex{
        display: flex;
        justify-content: space-around;
    }
    .copyright{
        text-align: center;
        padding: 15px;
        font-size: 11px;
        background-color: #13141a;
    }
}

/* media screen  mobile view */


@media screen and (max-width : 460px) {
    .brandName{
        font-size: 14px;
        padding-bottom: 10px;
    }
    .footer{
        padding-top: 20px;
        color: white;
        font-family: 'poppins';
        background-color: #23262f;
    }
    .flex{
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        gap: 10px;
    }
    .footerHead{
        display: flex;  
        flex-direction: column;
    }
    
    .contact>p{
        padding-bottom: 1px;
        font-size: 17px;
        letter-spacing: 1px;
    }
    
    
    .phone{
        letter-spacing: 1px;
        display: flex;
        gap: 10px;
        padding-bottom: 10px;
    }
    .phone>h2{
        padding-top: 10px;
        font-size: 15px;
    }
    .phone>img{
        margin-top: 15px;
        width: 20px;
        height: 20px;
    }
    .address{
        padding-bottom: 20px;
    }
    .location{
        display: flex;
        gap: 10px;
    }
    .location>h1{
        font-size: 17px;
    }
    .location>img{
        width: 20px;
        height: 20px;
    }
    .address>p{
        font-size: 14px;
        padding-left: 40px;
    }
    .address>h5{
        padding-left: 30px;
        color: yellowgreen;
    }
    
    
    .copyright{
        text-align: center;
        padding: 10px;
        font-size: 10px;
        background-color: #13141a;
    }
    .contact:hover{
        color: red;
        transform: scale(1.1); 
        transition-duration: 1000ms;
    }

    
.social_media{
    width: 25px;
    border: 1px solid whitesmoke;
    padding: 10px;
    border-radius: 20px;
    margin: 10px;
}
}
