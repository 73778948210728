@import url(https://fonts.googleapis.com/css2?family=Alkatra&family=Arimo:wght@500&family=Comfortaa&family=Kaushan+Script&family=League+Spartan:wght@400;700&family=Oswald&family=Pacifico&family=Poppins&family=Saira&family=Sen&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* header section image and text */
body{
    overflow-x: hidden;
    
}

.headImg{
    width: auto;
    height: 110vh;
    background-size: cover;
    position: relative;
}

.bgimg{
    position:absolute;
    left:0px;
    top:0px;
    z-index:-1;
}

.headText{
    text-align: center;
    width: 70%;
    margin: auto;
    padding-top: 350px;
}

.headText>h1{
    font-family: 'poppins';
    font-size: 32px;
    font-weight: 600;
    color: white;
    padding-bottom: 20px;
}

.headText>p{
    width: 60%;
    margin: auto;
    color: white;
    font-family: 'saira';
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 25px;
}
.contBtn{
    padding: 6px 30px;
    border-radius: 10px;
    border: transparent;
    font-family: 'poppins';
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: rgba(255, 255, 255, 0.925);
    color: black;
}

.contBtn>a{
    text-decoration: none;
}

.contBtn:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1); 
    transition-duration: 1000ms;
    cursor: pointer;
    background-color: white;
    color: blue;
}




.wtsapp{
    top: 85%;
    left: 91%;
    width: 70px;
    position: fixed;
    z-index: 100;
    cursor: pointer;
    padding: 7px;
    background-color: rgb(14, 80, 14);
    border-radius: 50%;
}

.wtsapp:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1); 
    transition-duration: 1000ms;

}
.phonewtsapp{
    display: none;
}
.quotes{
    border-radius: 50px 1px;
    padding: 50px;
    width: 90%;
    align-items: center;
    margin: auto;
    background-color: #2f333d;
   
}

.hold{
    color: green;
    font-size: 28px;
}
.quo_p{
    font-size: 18px;
    width: 90%;
    align-items: center;
    text-align: center;
    margin: auto;
    color: white;
}


/* media screen  tab view */

@media screen and (max-width : 929px) {
    .headImg{
        width: auto;
        height: 80vh;
        background-size: cover;
        padding-top: 30vh;
    }
    
    .headText{
        width: auto;
        position: absolute;
        top: 80px;
    }
    .bgimg{
        position:absolute;
        left:0px;
        top:0px;
        object-fit:cover;
        z-index:-1;
    }
    
    .headText>h1{
        font-family: 'poppins';
        width: 90%;
        margin: auto;
        font-size: 28px;
        font-weight:500;
        color: white;
        padding-bottom: 20px;
    }
    
    .headText>p{
        width: 70%;
        margin: auto;
        color: white;
        font-family: 'poppins';
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 25px;
    }
    .contBtn{
        padding: 6px 35px;
        border-radius: 10px;
        border: transparent;
        font-family: 'saira';
        font-size: 20px;
        font-weight: 800;
        letter-spacing: 2px;
        background-color: rgba(255, 255, 255, 0.548);
        color: white;
    }
    
    
    
    
    .wtsapp{
        top: 85%;
        left: 82%;
        width: 85px;
        position: fixed;
        cursor: pointer;
        padding: 7px;
        background-color: rgb(14, 80, 14);
        border-radius: 50%;
    }
    
    .wtsapp:hover{
        -webkit-transform: scale(1.1);
                transform: scale(1.1); 
        transition-duration: 1000ms;
    
    }
    .phonewtsapp{
    display: none;
}

}

/* media screen  mobile view */


@media screen and (max-width : 460px) {

    .headImg{
        width: 100%;
        height: 20vh;
        padding-top: 290px;
    }
    
    .headText{
        width: auto;
        position: absolute;
        top: -60px;
    }
    .bgimg{
        position:absolute;
        left:0px;
        top:0px;
        object-fit:cover;
        z-index:-1;
    }
    
    .headText>h1{
        font-family: 'poppins';
        font-size:17px;
        font-weight: 600;
        color: white;
        padding-bottom: 10px;
    }
    
    .headText>p{
        width: 90%;
        margin: auto;
        color: white;
        font-family: 'poppins';
        font-size: 12px;
        font-weight: 400;
        padding-bottom: 10px;
    }
    .contBtn{
        padding: 5px 12px;
        border-radius: 10px;
        border: transparent;
        font-family: 'saira';
        font-size: 13px;
        font-weight: 800;
        letter-spacing: 2px;
        background-color: rgba(255, 255, 255, 0.548);
        color: white;
    }
    
    
    
    
    .wtsapp{
        top: 88%;
        left: 75%;
        width: 65px;
        height: 65px;
        position: fixed;
        z-index: 100;
        cursor: pointer;
        padding: 7px;
        background-color: rgb(14, 80, 14);
        border-radius: 50%;
        display: block;
    }
    
    .phonewtsapp{
        display: block;
    }
    .quotes{
        border-radius: 30px 1px;
        padding: 10px;
        width: 90%;
        align-items: center;
        margin: auto auto 30px auto ;
        background-color: #2f333d;
    }
    .hold{
        color: green;
        font-size: 22px;
    }
    .quo_p{
        line-height: 32px;
        font-size: 12px;
        width: 95%;
        align-items: center;
        text-align: center;
        margin: auto;
        color: white;
    }

}

/* our services */
.ourServices{
    width: 100%;
    padding-bottom: 20px;
    background-color: white;
    font-family: 'poppins';
    padding-top: 80px;
}

.topSecServices{
    padding: 10px;
    margin: auto;
    width: 80%;
    text-align: center;
}

.prod_flex img{
    object-fit:contain;
}

.topSecServices>p{
    color: red;
    font-size: 14px;
}
.topSecServices>h2{
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
}
.servicesFlex{
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    grid-gap: 20px;
    gap: 20px;
    grid-auto-rows: minmax(10px , auto);
    margin:5px;
    padding: 10px;
}
.insideServices{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 400px;
    height: 400px;
    font-family: 'poppins';
    border-radius: 15px;
    padding: 20px 10px;
}

.insideServices:hover{
    -webkit-transform: scale(1.01);
            transform: scale(1.01); 
    transition-duration: 1000ms;
    border-radius: 10px;
    cursor: pointer;
}
.insideServices>h4{
    padding-top: 20px;
    text-align: center;
    padding-bottom: 10px;
    font-size: 16px;
    padding-left: 5px;
}

.insideServices>p{
    font-size: 12px;
    text-align: center;
    width: 90%;
    margin: auto;
}
.insideServices>img{
    display: flex;
    align-items: center;
    margin: auto;
    object-fit:inherit;    
    width: 350px;
    height: 200px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.prod_flex{
    display: grid;
    grid-template-columns: auto auto ;
    grid-gap: 10px;
    gap: 10px;
    text-align: center;
}
.prod_flex img{
    width: 200px;
    height: 90px;
}




/* media screen  tab view */

@media screen and (max-width : 929px) {
    .ourServices{
        height: auto;
        padding-top: 0px;
        background-color: white;
        font-family: 'poppins';
    }
    
    .topSecServices{
        padding: 5px;
        margin: auto;
        width: auto;
        text-align: center;
    }
    
    .topSecServices>p{
        color: red;
        font-size: 16px;
    }
    .topSecServices>h2{
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
    }
    .servicesFlex{
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
    }
    .insideServices{
        width: auto;
        height: auto;
        font-family: 'poppins';
        border-radius: 10px;

    }
    .insideServices>h4{
        font-size: 24px;
        text-align: center;
    }
    
    .insideServices>p{
        text-align: center;
        padding-left: 5px;
        font-size: 12px;
        width: 90%;
        margin: auto;
    }
    .insideServices>img{
        object-fit:cover;    
        width: 90%;
        height: 100%;
        margin-left: 50px;

    }
    .insideServices:hover{
        -webkit-transform: scale(1.05);
                transform: scale(1.05); 
        transition-duration: 1000ms;
        border-radius: 10px;
        cursor: pointer;
        
    }
}

/* media screen  mobile view */


@media screen and (max-width : 460px) {
    .ourServices{
        height: auto;
        padding-top: 0px;
        background-color: white;
        font-family: 'poppins';
    }
    
    .topSecServices{
        padding: 5px;
        margin: auto;
        width: auto;
        text-align: center;
    }
    
    .topSecServices>p{
        color: red;
        font-size: 14px;
        font-weight: 500;
    }
    .topSecServices>h2{
        font-size: 17px;
        text-transform: uppercase;
    }
    .servicesFlex{
        display: flex;
        flex-direction: column;
    }
    .insideServices{
        width: auto;
        height: auto;
        font-family: 'poppins';
    }
    .insideServices>h4{
        font-size: 20px;
        text-align: center;
    }
    
    .insideServices>p{
        text-align: center;
        font-size: 12px;
        width: 100%;
        margin: auto;
    }
    .insideServices>img{
        width: 100%;
        margin-left: 1px;

    }
    .insideServices:hover{
        -webkit-transform: scale(1.05);
                transform: scale(1.05); 
        transition-duration: 1000ms;
        cursor: pointer;
        
    }

    .prod_flex{
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 10px;
        gap: 10px;
        text-align: center;
    }
    .prod_flex img{
        width: 11rem;
        height: 7rem;
    }
}

/* portfolio section */
.portfolioTop{
    padding: 80px 0;
    background-color: whitesmoke;
}


.portfolioText{
    text-align: center;
    width: 70%;
    margin: auto;
    font-family: 'poppins';
    padding: 20px;
}

.portfolioText>h2{
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 600;
    padding-bottom: 10px;
}

.portfolioText>p{
    color: red;
    font-size: 14px;
}

.portfolioImages{
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 30px;
}

.portfolioImages>img{
    width: 300px;
    height: 200px;
    border-radius: 10px;
}


.portfolioProjects{
    border: 0.01px solid ;
    height: 100px;
    text-align: center;
    color: white;
    background-color: #2f333d ;
    font-family: 'poppins';
    text-transform: uppercase;
    margin: auto;
}

.h4{
    color: white;
    text-decoration: none;
    border: 1px solid white;
    width: 50%;
    padding: 10px;
    margin: 20px auto auto ;
}

.portfolioProjects>h4:hover{
    -webkit-transform: scale(1.01);
            transform: scale(1.01); 
    transition-duration: 10s ease-out;
    cursor: pointer;

}
.portfolioImages>img:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1); 
    transition-duration: 1000ms;
    border-radius: 20px;
    cursor: pointer;

}


/* media screen  tab view */

@media screen and (max-width : 929px) {
    .portfolioTop{
        padding-top: 10px;
        background-color: whitesmoke;
    }
    
    
    .portfolioText{
        text-align: center;
        width: auto;
        margin: auto;
        font-family: 'poppins';
        padding: 20px;
    }
    
    .portfolioText>h2{
        text-transform: uppercase;
        font-size: 24px;
    }
    
    .portfolioText>p{
        color: red;
        font-size: 16px;
        font-weight: 500;
    }
    
    .portfolioImages{
        display: flex;
        grid-gap: 20px;
        gap: 20px;
        padding: 40px;
        flex-direction: column;
    }
    
    .portfolioImages>img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    
    .portfolioProjects{
        margin-top: 20px;
        height: 80px;
        text-align: center;
        color: white;
        background-color: #2f333d ;
        font-family: 'poppins';
        text-transform: uppercase;
    }
    
    .portfolioProjects>h4{
        border: 1px solid white;
        width: 50%;
        padding: 10px;
        margin: 20px auto auto ;
    }
    .portfolioProjects>h4:hover{
        -webkit-transform: scale(1.1);
                transform: scale(1.1); 
        transition-duration: 1000ms;
        cursor: pointer;
    
    }
    .portfolioImages>img:hover{
        -webkit-transform: scale(1.01);
                transform: scale(1.01); 
        transition-duration: 1000ms;
        border-radius: 20px;
        cursor: pointer;
    
    }
}

/* media screen  mobile view */


@media screen and (max-width : 460px) {
    .portfolioTop{
        padding-top: 10px;
        background-color: whitesmoke;
    }
    
    .portfolioText{
        text-align: center;
        width: auto;
        margin: auto;
        font-family: 'poppins';
        padding: 20px;
    }
    
    .portfolioText>h2{
        text-transform: uppercase;
        font-size: 17px;
    }
    
    .portfolioText>p{
        color: red;
        font-size: 14px;
        font-weight: 500;
    }
    
    .portfolioImages{
        display: flex;
        grid-gap: 20px;
        gap: 20px;
        padding: 20px;
        flex-direction: column;
    }
    
    .portfolioImages>img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    
    .portfolioProjects{
        margin-top: 10px;
        height: 80px;
        text-align: center;
        color: white;
        background-color: #2f333d ;
        font-family: 'poppins';
        text-transform: uppercase;
    }
    
    .portfolioProjects>h4{
        border: 1px solid white;
        width: 50%;
        font-size: 14px;
        letter-spacing: 2px;
        padding: 10px;
        margin: 20px auto auto ;
    }

    .portfolioProjects>h4:hover{
        -webkit-transform: scale(1.1);
                transform: scale(1.1); 
        transition-duration: 1000ms;
        cursor: pointer;
    
    }
    .portfolioImages>img:hover{
        -webkit-transform: scale(1.01);
                transform: scale(1.01); 
        transition-duration: 1000ms;
        border-radius: 20px;
        cursor: pointer;
    
    }
}

/* Furniture and hometheaters  */

.aboutProct{
    padding: 50px;
}
.aboutFurniture{
    display: flex;
    justify-content: space-evenly;
}

.furnitureImageFlex{
    padding: 10px;
}

.furnitureImageFlex>img{
    width: 100%;
    border-radius: 10px;
}

.furnitureImageFlex>img:hover{
    -webkit-transform: scale(1.02);
            transform: scale(1.02); 
    transition-duration: 1000ms;

}

.furnitureText{
    margin: auto;
    padding: 30px;
    font-family: 'poppins';
}

.furnitureText:hover{
    -webkit-transform: scale(1.02);
            transform: scale(1.02); 
    transition-duration: 1000ms;

}

.furnitureText>h1{
    font-size: 24px;
    padding-bottom: 20px;
}

.furnitureText>p{
    font-size: 12px;
}

/* why we are */

.whyweare{
    display: flex;
    justify-content: space-evenly;
    grid-gap: 20px;
    gap: 20px;
    padding: 15px;
    text-align: center;
    color: white;
}
.whowe{
    padding: 20px;
    background-color: #161922;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}
.whowe h3{
    text-align: center;
}
.whowe p{
    padding: 10px;
}
.whowe img{
    width: 100px;
}


/* media screen  tab view */

@media screen and (max-width : 929px) {

    .aboutProct{
        padding: 30px;
    }
    .aboutFurniture{
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
    }
    
    .furnitureImageFlex{
        padding: 10px;
    }
    
    .furnitureImageFlex>img{
        width: 100%;
        border-radius: 20px;
        padding: 10px;

    }
    
    .furnitureImageFlex>img:hover{
        -webkit-transform: scale(1.02);
                transform: scale(1.02); 
        transition-duration: 1000ms;
    
    }
    
    .furnitureText{
        margin: auto;
        padding: 30px;
        font-family: 'poppins';
    }
    
    .furnitureText:hover{
        -webkit-transform: scale(1.02);
                transform: scale(1.02); 
        transition-duration: 1000ms;
    
    }
    
    .furnitureText>h1{
        font-size: 24px;
        padding-bottom: 10px;
    }
    
    .furnitureText>p{
        font-size: 13px;
        width: 100%;
    }
}

/* media screen  mobile view */


@media screen and (max-width : 460px) {
    .aboutProct{
        padding: 20px;
    }
    .aboutFurniture{
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
        border-radius: 10px;
        display: flex;
        grid-gap: 0px;
        gap: 0px;
        flex-direction: column;
    }
    
    .furnitureImageFlex{
        padding: 10px;
    }
    
    .furnitureImageFlex>img{
        width: 100%;
        border-radius: 20px;
        padding: 10px;

    }
    
    .aboutFurniture{
        -webkit-transform: scale(1.02);
                transform: scale(1.02); 
        transition-duration: 1000ms;
    
    }
    
    .furnitureText{
        margin: auto;
        padding: 30px;
        font-family: 'poppins';
    }
    
    .furnitureText>h1{
        font-size: 17px;
        padding-bottom: 10px;
    }
    
    .furnitureText>p{
        font-size: 12px;
    }
    /* why we are */

    .whyweare{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        grid-gap: 20px;
        gap: 20px;
        padding:10px 10px;
        text-align: center;
        color: white;
    }
    .whowe{
        padding: 20px;
        background-color: #161922;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    }
    .whowe h3{
        text-align: center;
    }
    .whowe p{
        width: 100%;
        padding: 10px;
    }
    .whowe img{
        width: 150px;
    }
}



.aboutUs{
    padding-top: 20px;
    padding-bottom: 10px;
    border: 1px solid transparent;
    width: 100%;
    font-family: 'poppins';
    background-color: #5f6572;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='152' viewBox='0 0 152 152'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='temple' fill='%232f333d' fill-opacity='0.19'%3E%3Cpath d='M152 150v2H0v-2h28v-8H8v-20H0v-2h8V80h42v20h20v42H30v8h90v-8H80v-42h20V80h42v40h8V30h-8v40h-42V50H80V8h40V0h2v8h20v20h8V0h2v150zm-2 0v-28h-8v20h-20v8h28zM82 30v18h18V30H82zm20 18h20v20h18V30h-20V10H82v18h20v20zm0 2v18h18V50h-18zm20-22h18V10h-18v18zm-54 92v-18H50v18h18zm-20-18H28V82H10v38h20v20h38v-18H48v-20zm0-2V82H30v18h18zm-20 22H10v18h18v-18zm54 0v18h38v-20h20V82h-18v20h-20v20H82zm18-20H82v18h18v-18zm2-2h18V82h-18v18zm20 40v-18h18v18h-18zM30 0h-2v8H8v20H0v2h8v40h42V50h20V8H30V0zm20 48h18V30H50v18zm18-20H48v20H28v20H10V30h20V10h38v18zM30 50h18v18H30V50zm-2-40H10v18h18V10z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    
}

.aboutUs>p{
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: 500;
}

.aboutUsContainer{
    margin: 20px;
    display: flex;
    justify-content: space-around;
}

.aboutUsDes{
    background-color: white;
    border-radius: 10px;
    width: 25%;
    padding: 30px;
    height: auto;
    align-items: center;
    text-align: center;
}
.aboutUsDes>img{
    object-fit:contain;
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.aboutUsDes>h1{
    padding-top: 20px;
    font-size: 24px;
}

.aboutUsDes>p{
    margin-top: 5px;
    font-size: 17px;
}
.maps{
    background-color: #5f6572;
    margin: 5px;
    padding: 5px;
    width: 60%;
    border-radius: 20px;
}

/* get in touch */

.getInTouch {
    background-color: #cfc9d7;
background-image: url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%23431b83' fill-opacity='0.08' fill-rule='evenodd'/%3E%3C/svg%3E");
    height: auto;
    text-align: center;
    align-items: center;
    padding: 10px;
    margin: auto;
    color: black;
  }
  #name,
  #email,
  #query,
  #phone {
    border: 1px solid black;
    width: 30%;
    padding: 10px 8px;
  }
  #name:focus {
    background-color: #e3f4f4;
    color: rgb(0, 0, 0);
    font-size: large;
  }
  #email:focus {
    background-color: #e3f4f4;
    color: rgb(0, 0, 0);
    font-size: large;
  }
  #phone:focus {
    background-color: #e3f4f4;
    color: rgb(0, 0, 0);
    font-size: large;
  }
.msgBtn{
    padding: 20px;
    background-color: rgb(85, 165, 199); 
    font-family: poppins;
    border-radius: 20px;
    margin: 10px;
}
#email:focus {
  background-color: #e3f4f4;
  color: rgb(0, 0, 0);
  font-size: large;
}
#phone:focus {
  background-color: #e3f4f4;
  color: rgb(0, 0, 0);
  font-size: large;
}
.msgBtn {
  padding: 20px;
  background-color: rgb(85, 165, 199);
  font-family: poppins;
  border-radius: 20px;
  margin: 10px;
}
.inputBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.textName {
  text-align: right;
}
.msgs{
    width: 35px;
}
.doc{
    width: 50px;
}

.phoneCall{
    width: 25px;
}
.icons{
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
}

.pen{
    width: 35px;
}

.msgBtn {
    margin-top: 1rem;
    position: relative;
    padding: 10px 20px;
    border-radius: 50px;
    border: 2px solid rgb(61, 106, 255);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    background: transparent;
    color: #55a5c7;
    overflow: hidden;
    box-shadow: 0 0 0 0 transparent;
    transition: all 0.2s ease-in;
  }
  
.msgBtn:hover {
    color: white;
    background: rgb(61, 106, 255);
    transition: all 0.2s ease-out;
  }
  
  .msgBtn:hover::before {
    -webkit-animation: sh02 0.5s 0s linear;
    animation: sh02 0.5s 0s linear;
  }
  
  .msgBtn::before {
    content: '';
    display: block;
    width: 0px;
    height: 86%;
    position: absolute;
    top: 7%;
    left: 0%;
    opacity: 0;
    background: #fff;
    box-shadow: 0 0 50px 30px #fff;
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
  }
  
  @-webkit-keyframes sh02 {
    from {
      opacity: 0;
      left: 0%;
    }
  
    50% {
      opacity: 1;
    }
  
    to {
      opacity: 0;
      left: 100%;
    }
  }
  
  @keyframes sh02 {
    from {
      opacity: 0;
      left: 0%;
    }
  
    50% {
      opacity: 1;
    }
  
    to {
      opacity: 0;
      left: 100%;
    }
  }
  
  .msgBtn:active {
    box-shadow: 0 0 0 0 transparent;
    transition: box-shadow 0.2s ease-in;
  }






/* media screen  tab view */

@media screen and (max-width : 929px) {
    .aboutUs{
        padding-top: 40px;
        padding-bottom: 50px;
        width: auto;
        font-family: 'poppins';
        background-color: #5f6572;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='152' viewBox='0 0 152 152'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='temple' fill='%232f333d' fill-opacity='0.19'%3E%3Cpath d='M152 150v2H0v-2h28v-8H8v-20H0v-2h8V80h42v20h20v42H30v8h90v-8H80v-42h20V80h42v40h8V30h-8v40h-42V50H80V8h40V0h2v8h20v20h8V0h2v150zm-2 0v-28h-8v20h-20v8h28zM82 30v18h18V30H82zm20 18h20v20h18V30h-20V10H82v18h20v20zm0 2v18h18V50h-18zm20-22h18V10h-18v18zm-54 92v-18H50v18h18zm-20-18H28V82H10v38h20v20h38v-18H48v-20zm0-2V82H30v18h18zm-20 22H10v18h18v-18zm54 0v18h38v-20h20V82h-18v20h-20v20H82zm18-20H82v18h18v-18zm2-2h18V82h-18v18zm20 40v-18h18v18h-18zM30 0h-2v8H8v20H0v2h8v40h42V50h20V8H30V0zm20 48h18V30H50v18zm18-20H48v20H28v20H10V30h20V10h38v18zM30 50h18v18H30V50zm-2-40H10v18h18V10z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        
    }
    
    .aboutUs>p{
        text-align: center;
        color: white;
        font-size: 22px;
        font-weight: 400;
    }
    
    .aboutUsContainer{
        margin: 20px;
        display: flex;
        flex-direction: column;
        grid-gap: 40px;
        gap: 40px;
        align-items: center;
    }
    
    .aboutUsDes{
        background-color: white;
        border-radius: 10px;
        width: 70%;
        padding: 30px;
        align-items: center;
        text-align: center;
    }
    .aboutUsDes>img{
        object-fit: cover;
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }
    
    .aboutUsDes>h1{
        padding: 20px 0;
    }
    
}



/* media screen  mobile view */


@media screen and (max-width : 460px) {
    .aboutUs{
        padding-top: 50px;
        padding-bottom: 30px;
        width: auto;
        font-family: 'poppins';
        background-color: #5f6572;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='152' viewBox='0 0 152 152'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='temple' fill='%232f333d' fill-opacity='0.19'%3E%3Cpath d='M152 150v2H0v-2h28v-8H8v-20H0v-2h8V80h42v20h20v42H30v8h90v-8H80v-42h20V80h42v40h8V30h-8v40h-42V50H80V8h40V0h2v8h20v20h8V0h2v150zm-2 0v-28h-8v20h-20v8h28zM82 30v18h18V30H82zm20 18h20v20h18V30h-20V10H82v18h20v20zm0 2v18h18V50h-18zm20-22h18V10h-18v18zm-54 92v-18H50v18h18zm-20-18H28V82H10v38h20v20h38v-18H48v-20zm0-2V82H30v18h18zm-20 22H10v18h18v-18zm54 0v18h38v-20h20V82h-18v20h-20v20H82zm18-20H82v18h18v-18zm2-2h18V82h-18v18zm20 40v-18h18v18h-18zM30 0h-2v8H8v20H0v2h8v40h42V50h20V8H30V0zm20 48h18V30H50v18zm18-20H48v20H28v20H10V30h20V10h38v18zM30 50h18v18H30V50zm-2-40H10v18h18V10z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        
    }
    
    .aboutUs>p{
        text-align: center;
        color: white;
        font-size: 22px;
        font-weight: 400;
    }
    
    .aboutUsContainer{
        margin: 20px;
        display: flex;
        flex-direction: column;
        grid-gap: 40px;
        gap: 40px;
        align-items: center;
    }
    
    .aboutUsDes{
        background-color: white;
        width: 80%;
        height: 90%;
        border-radius: 10px;
        padding: 30px;
        align-items: center;
        text-align: center;
    }
    .aboutUsDes>img{
        object-fit: cover;
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }
    
    .aboutUsDes>h1{
        padding: 20px 0;
    }


    .maps{
        margin: 0px;
        padding: 5px;
        width: 90%;
        border-radius: 20px;
    }

    
        /* get in touch */
        .getInTouch{
            height: auto;
            text-align: center;
            align-items: center;
            margin: auto;
        }
        .getInTouch div p{
            font-size: 16px;
        }
        .getInTouch div h1{
            font-size: 27px;
        }
        #name{
            border: 1px solid black;
            width: 90%;
            padding: 10px 150px ;
        }
        .msgBtn{
            font-size: 18px;
            padding: 15px 80px;
            background-color: rgb(85, 165, 199); 
            color: black;
            font-family: poppins;
            border-radius: 20px;
            margin: 10px;
        }
        .inputBox{
            font-size: 28px;
            display: flex;
            flex-direction: column;
            align-items: center;
            grid-gap: 5px;
            gap: 5px;
        }
        .textName{
            text-align: right;
        }

        /* About */
        .aboutus{
            padding: 2rem;
            background-color: rgb(218, 216, 216);
            width: 90%;
            align-items: center;
            margin: auto;
            margin-bottom: 30px;
            border-radius: 50px 1px;

        }
       

        #name,
        #email,
        #query,
        #phone {
            border: 1px solid black;
            width: 90%;
            padding: 10px 10px;
        }
        #name:focus {
            background-color: #e3f4f4;
            color: rgb(0, 0, 0);
            font-size: large;
        }
        #email:focus {
            background-color: #e3f4f4;
            color: rgb(0, 0, 0);
            font-size: large;
        }
        #phone:focus {
            background-color: #e3f4f4;
            color: rgb(0, 0, 0);
            font-size: large;
  }
}

.galleryWrap{
    padding-top: 80px;
    background-color: #998f8f;
    padding-left: 10px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap:20px;
    gap:20px;
    align-items: center;
    justify-content: center;
    max-width:100%;
    margin: auto;
}
.oneTwo{
    padding-bottom: 50px;
    background-color: #998f8f;
}
.galleryWrap .single {
    width: 400px;
    padding: 10px;
    cursor: pointer;
    border: 1px solid white;
  }

.galleryWrap .single img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.galleryWrap .single img:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }

.slideWrap{
    padding-top: 80px;
    background-color:  rgb(59, 58, 58);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.slideWrap .btnClose, .slideWrap .btnLeft, .slideWrap .btnRight{
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 999;
}

.btnClose , .btnLeft , .btnRight{
    height: 30px;
}

.btnClose:hover, .btnLeft:hover, .btnRight:hover{
    opacity: 1;
}

.slideWrap .btnClose{
    top: 40px;
    right: 40px;
}

.slideWrap .btnLeft{
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 40px;
}

.slideWrap .btnRight{
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 40px;
}

.fullScreen{
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullScreen img{
    max-width: 100%;
    max-height: 100%;
    -webkit-user-select: none;
    user-select: none;
}


.galleryWrap>h1{
    color: white;
    text-align: center;    
    border: 1px solid white;
    width: 70%;
    margin: auto;
}











/* media screen  tab view */

@media screen and (max-width : 929px) {

    .galleryWrap{
        padding-top: 80px;
        background-color: #7a7474;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap:10px;
        gap:10px;
        align-items: center;
        justify-content: center;
        max-width:1005;
        margin: 0 auto;
        padding-bottom: 50px;
    }
    .galleryWrap .single {
        width: 100%;
        padding: 10px;
        cursor: pointer;
        border: 1px solid white;
      }
    
    .galleryWrap .single img {
      width: 350px;
      height: 200px;
      margin: auto;
        display: flex;
    }
    
    .galleryWrap .single img:hover {
        -webkit-transform: scale(1.02);
                transform: scale(1.02);
      }
    
    .slideWrap{
        padding-top: 80px;
        background-color:  rgba(59, 58, 58, 0.849);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    
    .slideWrap .btnClose, .slideWrap .btnLeft, .slideWrap .btnRight{
        position: fixed;
        cursor: pointer;
        opacity: 0.6;
        color: #fff;
        z-index: 999;
    }
    
    .btnClose , .btnLeft , .btnRight{
        height: 50px;
    }
    
    .btnClose:hover, .btnLeft:hover, .btnRight:hover{
        opacity: 1;
    }
    
    .slideWrap .btnClose{
        top: 40px;
        right: 40px;
    }
    
    .slideWrap .btnLeft{
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        left: 40px;
    }
    
    .slideWrap .btnRight{
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        right: 40px;
    }
    
    .fullScreen{
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .fullScreen img{
        max-width: 100%;
        max-height: 100%;
        -webkit-user-select: none;
        user-select: none;
    }
}





/* media screen  mobile view */


@media screen and (max-width : 460px) {
    
    .galleryWrap{
        padding-top: 90px;
        background-color: #7a7474;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        grid-gap:10px;
        gap:10px;
        max-width:100%;
        margin: 0 auto;
        padding-bottom: 50px;
    }
    .oneTwo{
        background-color: #7a7474;
    }
    .galleryWrap>h1{
        color: white;
        text-align: center;
    }
    .galleryWrap .single {
        width: 90%;
        padding: 10px;
        cursor: pointer;
        border: 1px solid white;
      }
    
    .galleryWrap .single img {
      width: 350px;
      height: 200px;
      margin: auto;
      object-fit: cover;
        display: flex;
    }
    
    .galleryWrap .single img:hover {
        -webkit-transform: scale(1.02);
                transform: scale(1.02);
      }
    
    .slideWrap{
        padding-top: 80px;
        background-color:  rgba(59, 58, 58, 0.849);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    
    .slideWrap .btnClose, .slideWrap .btnLeft, .slideWrap .btnRight{
        position: fixed;
        cursor: pointer;
        opacity: 0.6;
        color: #fff;
        z-index: 999;
    }
    
    .btnClose , .btnLeft , .btnRight{
        height: 30px;
    }
    
    .btnClose:hover, .btnLeft:hover, .btnRight:hover{
        opacity: 1;
    }
    
    .slideWrap .btnClose{
        top: 330px;
        right: 40px;
    }
    
    .slideWrap .btnLeft{
        top: 55%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        left: 20px;
    }
    
    .slideWrap .btnRight{
        top: 55%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        right: 20px;
    }
    
    .fullScreen{
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .fullScreen img{
        max-width: 100%;
        max-height: 100%;
        -webkit-user-select: none;
        user-select: none;
    }
}
/* footer */
.footer{
    padding-top: 40px;
    color: white;
    font-family: 'poppins';
    background-color: #23262f;
}

.footerHead{
    display: flex;
    justify-content: space-around;
}
.flex{
    display: flex;
    grid-gap: 50px;
    gap: 50px;
}

.contact>p{
    padding-bottom: 12px;
    padding-left: 20px;
}


.phone{
    cursor: pointer;
    letter-spacing: 1px;
    display: flex;
    grid-gap:10px;
    gap:10px;
    padding-bottom: 5px;
}
.phone>h2{
    padding-top: 5px;
    font-size: 17px;
}
.phone>img{
    margin-top: 8px;
    width: 20px;
    height: 20px;
}
.location{
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}
.location>h1{
    font-size: 17px;
    padding-top: 10px;
}
.address>p{
    font-size: 14px;
}
.location>img{
    margin-top: 8px;
    width: 20px;
    height: 20px;
}


.copyright{
    text-align: center;
    padding: 10px;
    font-size: 12px;
    letter-spacing: 1px;
    background-color: #13141a;
}
.phoneNumber{
    text-decoration: none;
    color: aliceblue;
}
.logoCF{
    width: 200px;
}

.social_media{
    width: 20px;
    border: 1px solid whitesmoke;
    padding: 10px;
    border-radius: 20px;
    margin: 10px;
}


/* media screen  tab view */

@media screen and (max-width : 929px) {
    .footer{
        padding-top: 20px;
        color: white;
        font-family: 'poppins';
        background-color: #23262f;
    }
    .brandName{
        font-size: 14px;
        text-align: center;
        padding-bottom: 10px;
    }
    .footerHead{
        display: flex;
        flex-direction: column;
    }

    
    .contact>p{
        font-size: 16px;
        padding-bottom: 18px;
        padding-left: 30px;
    }
    
    
    .phone{
        letter-spacing: 1px;
        display: flex;
        grid-gap: 10px;
        gap: 10px;
        padding-bottom: 5px;
    }
    .phone>h2{
        padding-top: 5px;
        font-size: 13px;
    }
    .phone>img{
        margin-top: 8px;
        width: 15px;
        height: 15px;
    }
    .location{
        display: flex;
        grid-gap: 10px;
        gap: 10px;
    }
    .location>h1{
        font-size: 17px;
        padding-top: 10px;
    }
    .address>p{
        padding-top: 10px;
        font-size: 14px;
    }
    .location>img{
        width: 30px;
        height: 30px;
    }
    .contact>p>a:hover{
        color: red;
    }
    .flex{
        display: flex;
        justify-content: space-around;
    }
    .copyright{
        text-align: center;
        padding: 15px;
        font-size: 11px;
        background-color: #13141a;
    }
}

/* media screen  mobile view */


@media screen and (max-width : 460px) {
    .brandName{
        font-size: 14px;
        padding-bottom: 10px;
    }
    .footer{
        padding-top: 20px;
        color: white;
        font-family: 'poppins';
        background-color: #23262f;
    }
    .flex{
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        grid-gap: 10px;
        gap: 10px;
    }
    .footerHead{
        display: flex;  
        flex-direction: column;
    }
    
    .contact>p{
        padding-bottom: 1px;
        font-size: 17px;
        letter-spacing: 1px;
    }
    
    
    .phone{
        letter-spacing: 1px;
        display: flex;
        grid-gap: 10px;
        gap: 10px;
        padding-bottom: 10px;
    }
    .phone>h2{
        padding-top: 10px;
        font-size: 15px;
    }
    .phone>img{
        margin-top: 15px;
        width: 20px;
        height: 20px;
    }
    .address{
        padding-bottom: 20px;
    }
    .location{
        display: flex;
        grid-gap: 10px;
        gap: 10px;
    }
    .location>h1{
        font-size: 17px;
    }
    .location>img{
        width: 20px;
        height: 20px;
    }
    .address>p{
        font-size: 14px;
        padding-left: 40px;
    }
    .address>h5{
        padding-left: 30px;
        color: yellowgreen;
    }
    
    
    .copyright{
        text-align: center;
        padding: 10px;
        font-size: 10px;
        background-color: #13141a;
    }
    .contact:hover{
        color: red;
        -webkit-transform: scale(1.1);
                transform: scale(1.1); 
        transition-duration: 1000ms;
    }

    
.social_media{
    width: 25px;
    border: 1px solid whitesmoke;
    padding: 10px;
    border-radius: 20px;
    margin: 10px;
}
}

* {
	padding: 0;
	margin: 0;
    font-family: 'poppins';
    overflow-x: hidden;
}
.navBar {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 80px;
	padding: 0 2rem;
    background-color: #23262f;
	color: white;
    z-index: 10;
}
#logonav{
	display: none;
}
.navinside{
    margin-left: 600px;
}
h2>a{
    color: white;
    text-decoration: none;
}
nav a {
	margin: 0 1rem;
	color: white;
	text-decoration: none;
}

nav a:hover {
	color: red;
}
.whyus{
	color: yellow;
}

.whyus:hover{
	color: red;
}

.navBar .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: white;
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}
.navBar div,
nav {
	width: 100rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.logoC{
	width:300px ;
	margin-left: 100px;
	height: auto;
}


/* media screen  tab view */

@media screen and (max-width : 929px) {
	.navBar .nav-btn {
		visibility: visible;
		opacity: 1;
	}
    .navBar{
        position: fixed;
        z-index: 100;
        width: 100%;
    }
	.navBar div,nav {
		width: 20rem;
		display: flex;
		align-items: center;
	}

	.navBar nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		grid-gap: 1.5rem;
		gap: 1.5rem;
		background-color: #23262f;
		transition: 1s;
        z-index: 10;
	}

	.navBar .responsive_nav {
		-webkit-transform: translateY(100vh);
		        transform: translateY(100vh);
	}

    .navinside{
        margin-left: 0px;
    }

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
        z-index: 100 ;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
	#logonav{
		display: block;
	}
	.logo{
        display: flex;
		padding-top: 50px;
        grid-gap: 0px;
        gap: 0px;
    }
    .logo>img{
        width: 20px;
        height: 20px;
    }

    .logo>a>img{
        width: 35px;
        
    }
	.logoC{
		width:150px ;
		margin-left: 0px;
	}
}
