
.aboutUs{
    padding-top: 20px;
    padding-bottom: 10px;
    border: 1px solid transparent;
    width: 100%;
    font-family: 'poppins';
    background-color: #5f6572;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='152' viewBox='0 0 152 152'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='temple' fill='%232f333d' fill-opacity='0.19'%3E%3Cpath d='M152 150v2H0v-2h28v-8H8v-20H0v-2h8V80h42v20h20v42H30v8h90v-8H80v-42h20V80h42v40h8V30h-8v40h-42V50H80V8h40V0h2v8h20v20h8V0h2v150zm-2 0v-28h-8v20h-20v8h28zM82 30v18h18V30H82zm20 18h20v20h18V30h-20V10H82v18h20v20zm0 2v18h18V50h-18zm20-22h18V10h-18v18zm-54 92v-18H50v18h18zm-20-18H28V82H10v38h20v20h38v-18H48v-20zm0-2V82H30v18h18zm-20 22H10v18h18v-18zm54 0v18h38v-20h20V82h-18v20h-20v20H82zm18-20H82v18h18v-18zm2-2h18V82h-18v18zm20 40v-18h18v18h-18zM30 0h-2v8H8v20H0v2h8v40h42V50h20V8H30V0zm20 48h18V30H50v18zm18-20H48v20H28v20H10V30h20V10h38v18zM30 50h18v18H30V50zm-2-40H10v18h18V10z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    
}

.aboutUs>p{
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: 500;
}

.aboutUsContainer{
    margin: 20px;
    display: flex;
    justify-content: space-around;
}

.aboutUsDes{
    background-color: white;
    border-radius: 10px;
    width: 25%;
    padding: 30px;
    height: auto;
    align-items: center;
    text-align: center;
}
.aboutUsDes>img{
    object-fit:contain;
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.aboutUsDes>h1{
    padding-top: 20px;
    font-size: 24px;
}

.aboutUsDes>p{
    margin-top: 5px;
    font-size: 17px;
}
.maps{
    background-color: #5f6572;
    margin: 5px;
    padding: 5px;
    width: 60%;
    border-radius: 20px;
}

/* get in touch */

.getInTouch {
    background-color: #cfc9d7;
background-image: url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%23431b83' fill-opacity='0.08' fill-rule='evenodd'/%3E%3C/svg%3E");
    height: auto;
    text-align: center;
    align-items: center;
    padding: 10px;
    margin: auto;
    color: black;
  }
  #name,
  #email,
  #query,
  #phone {
    border: 1px solid black;
    width: 30%;
    padding: 10px 8px;
  }
  #name:focus {
    background-color: #e3f4f4;
    color: rgb(0, 0, 0);
    font-size: large;
  }
  #email:focus {
    background-color: #e3f4f4;
    color: rgb(0, 0, 0);
    font-size: large;
  }
  #phone:focus {
    background-color: #e3f4f4;
    color: rgb(0, 0, 0);
    font-size: large;
  }
.msgBtn{
    padding: 20px;
    background-color: rgb(85, 165, 199); 
    font-family: poppins;
    border-radius: 20px;
    margin: 10px;
}
#email:focus {
  background-color: #e3f4f4;
  color: rgb(0, 0, 0);
  font-size: large;
}
#phone:focus {
  background-color: #e3f4f4;
  color: rgb(0, 0, 0);
  font-size: large;
}
.msgBtn {
  padding: 20px;
  background-color: rgb(85, 165, 199);
  font-family: poppins;
  border-radius: 20px;
  margin: 10px;
}
.inputBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.textName {
  text-align: right;
}
.msgs{
    width: 35px;
}
.doc{
    width: 50px;
}

.phoneCall{
    width: 25px;
}
.icons{
    display: flex;
    justify-content: center;
    gap: 20px;
}

.pen{
    width: 35px;
}

.msgBtn {
    margin-top: 1rem;
    position: relative;
    padding: 10px 20px;
    border-radius: 50px;
    border: 2px solid rgb(61, 106, 255);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    background: transparent;
    color: #55a5c7;
    overflow: hidden;
    box-shadow: 0 0 0 0 transparent;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
  }
  
.msgBtn:hover {
    color: white;
    background: rgb(61, 106, 255);
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }
  
  .msgBtn:hover::before {
    -webkit-animation: sh02 0.5s 0s linear;
    -moz-animation: sh02 0.5s 0s linear;
    animation: sh02 0.5s 0s linear;
  }
  
  .msgBtn::before {
    content: '';
    display: block;
    width: 0px;
    height: 86%;
    position: absolute;
    top: 7%;
    left: 0%;
    opacity: 0;
    background: #fff;
    box-shadow: 0 0 50px 30px #fff;
    -webkit-transform: skewX(-20deg);
    -moz-transform: skewX(-20deg);
    -ms-transform: skewX(-20deg);
    -o-transform: skewX(-20deg);
    transform: skewX(-20deg);
  }
  
  @keyframes sh02 {
    from {
      opacity: 0;
      left: 0%;
    }
  
    50% {
      opacity: 1;
    }
  
    to {
      opacity: 0;
      left: 100%;
    }
  }
  
  .msgBtn:active {
    box-shadow: 0 0 0 0 transparent;
    -webkit-transition: box-shadow 0.2s ease-in;
    -moz-transition: box-shadow 0.2s ease-in;
    transition: box-shadow 0.2s ease-in;
  }






/* media screen  tab view */

@media screen and (max-width : 929px) {
    .aboutUs{
        padding-top: 40px;
        padding-bottom: 50px;
        width: auto;
        font-family: 'poppins';
        background-color: #5f6572;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='152' viewBox='0 0 152 152'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='temple' fill='%232f333d' fill-opacity='0.19'%3E%3Cpath d='M152 150v2H0v-2h28v-8H8v-20H0v-2h8V80h42v20h20v42H30v8h90v-8H80v-42h20V80h42v40h8V30h-8v40h-42V50H80V8h40V0h2v8h20v20h8V0h2v150zm-2 0v-28h-8v20h-20v8h28zM82 30v18h18V30H82zm20 18h20v20h18V30h-20V10H82v18h20v20zm0 2v18h18V50h-18zm20-22h18V10h-18v18zm-54 92v-18H50v18h18zm-20-18H28V82H10v38h20v20h38v-18H48v-20zm0-2V82H30v18h18zm-20 22H10v18h18v-18zm54 0v18h38v-20h20V82h-18v20h-20v20H82zm18-20H82v18h18v-18zm2-2h18V82h-18v18zm20 40v-18h18v18h-18zM30 0h-2v8H8v20H0v2h8v40h42V50h20V8H30V0zm20 48h18V30H50v18zm18-20H48v20H28v20H10V30h20V10h38v18zM30 50h18v18H30V50zm-2-40H10v18h18V10z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        
    }
    
    .aboutUs>p{
        text-align: center;
        color: white;
        font-size: 22px;
        font-weight: 400;
    }
    
    .aboutUsContainer{
        margin: 20px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
    }
    
    .aboutUsDes{
        background-color: white;
        border-radius: 10px;
        width: 70%;
        padding: 30px;
        align-items: center;
        text-align: center;
    }
    .aboutUsDes>img{
        object-fit: cover;
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }
    
    .aboutUsDes>h1{
        padding: 20px 0;
    }
    
}



/* media screen  mobile view */


@media screen and (max-width : 460px) {
    .aboutUs{
        padding-top: 50px;
        padding-bottom: 30px;
        width: auto;
        font-family: 'poppins';
        background-color: #5f6572;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='152' viewBox='0 0 152 152'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='temple' fill='%232f333d' fill-opacity='0.19'%3E%3Cpath d='M152 150v2H0v-2h28v-8H8v-20H0v-2h8V80h42v20h20v42H30v8h90v-8H80v-42h20V80h42v40h8V30h-8v40h-42V50H80V8h40V0h2v8h20v20h8V0h2v150zm-2 0v-28h-8v20h-20v8h28zM82 30v18h18V30H82zm20 18h20v20h18V30h-20V10H82v18h20v20zm0 2v18h18V50h-18zm20-22h18V10h-18v18zm-54 92v-18H50v18h18zm-20-18H28V82H10v38h20v20h38v-18H48v-20zm0-2V82H30v18h18zm-20 22H10v18h18v-18zm54 0v18h38v-20h20V82h-18v20h-20v20H82zm18-20H82v18h18v-18zm2-2h18V82h-18v18zm20 40v-18h18v18h-18zM30 0h-2v8H8v20H0v2h8v40h42V50h20V8H30V0zm20 48h18V30H50v18zm18-20H48v20H28v20H10V30h20V10h38v18zM30 50h18v18H30V50zm-2-40H10v18h18V10z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        
    }
    
    .aboutUs>p{
        text-align: center;
        color: white;
        font-size: 22px;
        font-weight: 400;
    }
    
    .aboutUsContainer{
        margin: 20px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
    }
    
    .aboutUsDes{
        background-color: white;
        width: 80%;
        height: 90%;
        border-radius: 10px;
        padding: 30px;
        align-items: center;
        text-align: center;
    }
    .aboutUsDes>img{
        object-fit: cover;
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }
    
    .aboutUsDes>h1{
        padding: 20px 0;
    }


    .maps{
        margin: 0px;
        padding: 5px;
        width: 90%;
        border-radius: 20px;
    }

    
        /* get in touch */
        .getInTouch{
            height: auto;
            text-align: center;
            align-items: center;
            margin: auto;
        }
        .getInTouch div p{
            font-size: 16px;
        }
        .getInTouch div h1{
            font-size: 27px;
        }
        #name{
            border: 1px solid black;
            width: 90%;
            padding: 10px 150px ;
        }
        .msgBtn{
            font-size: 18px;
            padding: 15px 80px;
            background-color: rgb(85, 165, 199); 
            color: black;
            font-family: poppins;
            border-radius: 20px;
            margin: 10px;
        }
        .inputBox{
            font-size: 28px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
        }
        .textName{
            text-align: right;
        }

        /* About */
        .aboutus{
            padding: 2rem;
            background-color: rgb(218, 216, 216);
            width: 90%;
            align-items: center;
            margin: auto;
            margin-bottom: 30px;
            border-radius: 50px 1px;

        }
       

        #name,
        #email,
        #query,
        #phone {
            border: 1px solid black;
            width: 90%;
            padding: 10px 10px;
        }
        #name:focus {
            background-color: #e3f4f4;
            color: rgb(0, 0, 0);
            font-size: large;
        }
        #email:focus {
            background-color: #e3f4f4;
            color: rgb(0, 0, 0);
            font-size: large;
        }
        #phone:focus {
            background-color: #e3f4f4;
            color: rgb(0, 0, 0);
            font-size: large;
  }
}
